import React, { useState } from "react"
import CTA from "../components/cta"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { loadStripe } from "@stripe/stripe-js"
import GuaranteeBadge from "../components/guarantee-badge"
import { Link } from "gatsby"

let stripePromise
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(
      "pk_live_51JYY4cGlZNC0CUqJD1FCCX91Qp0Yh2rsTYxr3ID7aDJZq7ZMY07bgDIbnw4oHY5A7oUHhFNPhIzzZH6gBZvI13BB004Ysj2Jrz"
    )
  }
  return stripePromise
}

const Pricing = () => {
  const faq = [
    {
      q: "What happens once i buy a list?",
      a:
        "Once you've purchased a list you will get it from us in an e-mail. We will also include a link to the list in a google sheet version. If you want more data which might be hard to visualize in a chart, like their latest posts etc you can create an account on omnifluence to view your list on the browser in a dashboard format.",
    },
    {
      q: "Do you work with the influencers on the list?",
      a:
        "We don't have any relationship with the influencers on theinfluencerlist. They are chosen based on their content and metrics alone.",
    },
    {
      q: "Do you provide updates to the lists?",
      a:
        "We only update the data on the subscription plan as it's taken directly from our database. If you want an update on a previous list you can contact us for a discount on a custom list with the same influencers.",
    },
    {
      q: "How do i contact influencers when I'm done searching?",
      a:
        "We recommend you reach out to them personally on DM's on the respective platform introducing yourself, to give it a personal touch.",
    },
    {
      q: "I want a refund",
      a: "Contact us at hey@theinfluencerlist.co",
    },
    {
      q: "I got scammed!",
      a:
        "We are sorry to hear that. While we don't have a preexisting releationship with the influencers in our list fraudalent behaviour is not something we tolerate on our list. We can't do anything about your situation but we can stop it from happening to someone else. Please contact us at email@mail.com with details of who it was.",
    },
    {
      q: "Whats Omnifluence?",
      a:
        "Omnifluence is our partner, and provides additional services when working with influencers. such as a influencer database, management dashboard and campaign link manager.",
    },
    {
      q: "I don't want my profile listed on theinfluencerlist",
      a:
        "No worries! Contact us with your profile details and we will remove you.",
    },
    {
      q: "I have a question that isn't answered here",
      a:
        "We are happy to answer all and any questions you have. Reach out to us on hey@theinfluencerlist.co",
    },
  ]

  const stripeCheckout = async (
    event,
    code = "price_1JkshKGlZNC0CUqJF0emwbTR"
  ) => {
    event.preventDefault()

    const stripe = await getStripe()

    const { error } = await stripe.redirectToCheckout({
      mode: "payment",
      lineItems: [{ price: "price_1JkshKGlZNC0CUqJF0emwbTR", quantity: 1 }],
      successUrl: `http://www.theinfluencerlist.co/success`,
      cancelUrl: `http://www.theinfluencerlist.co/cancel`,
    })

    if (error) {
      alert(`An error occured ${error.message}`)
    }
  }

  function gtag_report_conversion(url) {
    if (typeof window !== "undefined") {
      var callback = function () {
        if (typeof url != "undefined") {
          window.location = url
        }
      }
      window.gtag("event", "conversion", {
        send_to: "AW-614082583/QpTfCPX_3f0CEJfQ6KQC",
        event_callback: callback,
      })
      return false
    }
  }

  return (
    <Layout>
      <SEO title="Pricing" />
      <div className="map pb-8">
        <header className="text-center max-w-2xl px-4 sm:px-3 mx-auto pt-16 sm:pt-24 xl:pt-32 pb-16 sm:pb-24 md:pb-20 xl:pb-28">
          <h1 className="md:text-7xl sm:text-5.5xl font-extrabold tracking-tight mb-4">
            Save time searching for Influencers
          </h1>
          <p className="text-lg max-w-xl mx-auto mb-8">
            All plans and lists come with a 30-day money back guarantee. <br />{" "}
            Prices are in USD.
          </p>
        </header>
        <section className="flex flex-col lg:flex-row m-h-16 m-8 lg:divide-x-2 lg:divide-gray-400">
          {/*Card*/}
          <div className="flex-1 text-center relative">
            <div className="px-4 mx-auto py-8 rounded-lg grid grid-cols-1 mt-4">
              {/**Item Header */}
              <div className="flex flex-col border-b-2 mb-10">
                <svg
                  className="h-12 w-12 mb-4 self-center"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 32 32"
                >
                  <path d="M 16 4 C 9.3851594 4 4 9.3851594 4 16 C 4 22.614841 9.3851594 28 16 28 C 22.614841 28 28 22.614841 28 16 C 28 9.3851594 22.614841 4 16 4 z M 16 6 C 19.90863 6 23.276065 8.2333228 24.921875 11.492188 L 18.115234 11.488281 C 17.470061 11.183326 16.757777 11 16 11 C 14.375393 11 12.940126 11.796481 12.025391 13.005859 L 8.4394531 9.4589844 C 10.271365 7.342482 12.973068 6 16 6 z M 7.6328125 10.523438 L 11.019531 16.392578 C 11.205796 18.757556 13.052736 20.67055 15.388672 20.958984 L 14.113281 25.816406 C 9.4869714 24.936011 6 20.887395 6 16 C 6 13.974892 6.6030417 12.096075 7.6328125 10.523438 z M 25.441406 12.724609 C 25.796398 13.751526 26 14.850512 26 16 C 26 21.533597 21.533597 26 16 26 C 15.813874 26 15.6328 25.982633 15.449219 25.972656 L 18.841797 20.101562 C 20.142053 19.19639 21 17.695623 21 16 C 21 15.309909 20.857182 14.652149 20.601562 14.052734 L 25.441406 12.724609 z M 16 13 C 17.67148 13 19 14.328521 19 16 C 19 17.67148 17.67148 19 16 19 C 14.328521 19 13 17.67148 13 16 C 13 14.328521 14.328521 13 16 13 z" />
                </svg>
                <h2 className="mb-0">Chrome Extension</h2>
                <span className="text-sm text-gray-600 font-thin my-2">
                  Influencers insights in your browser
                </span>
                <h3 className="flex item-center justify-center mx-auto  items-center text-5xl sans tracking-tight font-normal no-underline mx-3">
                  Free <span className="ml-3 text-xl"></span>
                </h3>
              </div>
              <div className="h-2xl">
                <ul className="text-gray-600 mb-16">
                  <h3 className="uppercase text-black text-lg text-left ">
                    What's included
                  </h3>
                  <li className="flex  items-center mx-auto">
                    <svg
                      aria-hidden="true"
                      width="12"
                      height="8"
                      fill="none"
                      className="text-teal-400 mr-3"
                    >
                      <path
                        d="M1.5 4.5l2.236 2.236a1 1 0 001.467-.056L10.5.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    <span>View any Instagram profiles metrics</span>
                  </li>
                  <li className="flex  items-center mx-auto">
                    <svg
                      aria-hidden="true"
                      width="12"
                      height="8"
                      fill="none"
                      className="text-teal-400 mr-3"
                    >
                      <path
                        d="M1.5 4.5l2.236 2.236a1 1 0 001.467-.056L10.5.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    <span className="text-left">
                      Average post metrics such as engagement rate, likes &
                      comments
                    </span>
                  </li>
                  <li className="flex  items-center mx-auto">
                    <svg
                      aria-hidden="true"
                      width="12"
                      height="8"
                      fill="none"
                      className="text-teal-400 mr-3"
                    >
                      <path
                        d="M1.5 4.5l2.236 2.236a1 1 0 001.467-.056L10.5.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    <span className="text-left">
                      Video statistics, including reach and engagement
                    </span>
                  </li>
                </ul>
              </div>
              <a
                href="https://chrome.google.com/webstore/detail/influencer-insight-omnifl/bhgpimjehlhondcoagddglglegkajkmo?from=influencerlist"
                target="_blank"
                className="block bg-light-blue text-white font-semibold font-sans lg:w-64 p-3 rounded-lg mx-auto mt-12 hover:shadow-xl cursor-pointer"
              >
                Chrome Webstore
              </a>
            </div>
          </div>
          {/*Card 2 mid*/}
          <div className="flex-1 text-center relative">
            <h3 className="absolute w-full text-green uppercase tracking-wider text-sm font-normal">
              <span className="p-3 bg-light-green rounded-full">
                recommended
              </span>
            </h3>
            <div className="px-4 mx-auto py-8 rounded-lg grid grid-cols-1 mt-4">
              {/**Item Header */}
              <div className="flex flex-col border-b-2 mb-10 pb-4">
                <h2 className="mb-0">Influencer List</h2>
                <span className="text-sm text-gray-600 font-thin my-2">
                  Choose among our influencer lists
                </span>
                <h3 className="flex item-center justify-center mx-auto text-5xl items-center sans tracking-tight text-blue font-extrabold no-underline mx-3">
                  <span className="text-gray-500 mr-3 text-xl">from</span>$39{" "}
                  <span className="text-black ml-3 text-xl font-semibold">
                    USD <br />
                    <span className="text-sm text-gray-500">and up</span>
                  </span>
                </h3>
              </div>
              <div className="h-2xl">
                <ul className="text-gray-600 mb-16">
                  <h3 className="uppercase text-black text-lg text-left ">
                    What's included
                  </h3>
                  <li className="flex  items-center mx-auto">
                    <svg
                      aria-hidden="true"
                      width="12"
                      height="8"
                      fill="none"
                      className="text-teal-400 mr-3"
                    >
                      <path
                        d="M1.5 4.5l2.236 2.236a1 1 0 001.467-.056L10.5.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    <span>Between 600 and 2000 influencers per list</span>
                  </li>
                  <li className="flex  items-center mx-auto">
                    <svg
                      aria-hidden="true"
                      width="12"
                      height="8"
                      fill="none"
                      className="text-teal-400 mr-3"
                    >
                      <path
                        d="M1.5 4.5l2.236 2.236a1 1 0 001.467-.056L10.5.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    <span>Over 14 unique niches & categories</span>
                  </li>
                  <li className="flex  items-center mx-auto">
                    <svg
                      aria-hidden="true"
                      width="12"
                      height="8"
                      fill="none"
                      className="text-teal-400 mr-3"
                    >
                      <path
                        d="M1.5 4.5l2.236 2.236a1 1 0 001.467-.056L10.5.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    <span className="text-left">
                      Personalized options to create a list just for your needs
                    </span>
                  </li>
                  <li className="flex  items-center mx-auto">
                    <svg
                      aria-hidden="true"
                      width="12"
                      height="8"
                      fill="none"
                      className="text-teal-400 mr-3"
                    >
                      <path
                        d="M1.5 4.5l2.236 2.236a1 1 0 001.467-.056L10.5.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    <span className="text-left">
                      Engagement, follower and posts related metrics
                    </span>
                  </li>
                  <li className="flex  items-center mx-auto">
                    <svg
                      aria-hidden="true"
                      width="12"
                      height="8"
                      fill="none"
                      className="text-teal-400 mr-3"
                    >
                      <path
                        d="M1.5 4.5l2.236 2.236a1 1 0 001.467-.056L10.5.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    <span className="text-left">
                      Includes google sheet version, excel file version and a
                      omnifluence list
                    </span>
                  </li>
                </ul>
              </div>
              <Link
                to="/lists?pricing"
                className="block bg-dark-green text-white font-semibold font-sans lg:w-64 p-3 rounded-lg mx-auto mt-12 hover:shadow-xl cursor-pointer"
              >
                View Lists
              </Link>
              <div className="mt-4">
                <Link
                  className="italic underline text-gray-800 hover:text-gray-900"
                  to="/custom-list?pricing"
                >
                  Or create your own.
                </Link>
              </div>
            </div>
          </div>
          {/*Card 3*/}
          <div className="flex-1 text-center">
            <div className="px-4 mx-auto py-8 rounded-lg grid grid-cols-1 mt-4">
              {/**Item Header */}
              <div className="flex flex-col border-b-2 mb-10 pb-4">
                <svg
                  className="h-12 mb-4 w-12 self-center"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 32 32"
                >
                  <path d="M 16 4 C 10.886719 4 6.617188 7.160156 4.875 11.625 L 6.71875 12.375 C 8.175781 8.640625 11.710938 6 16 6 C 19.242188 6 22.132813 7.589844 23.9375 10 L 20 10 L 20 12 L 27 12 L 27 5 L 25 5 L 25 8.09375 C 22.808594 5.582031 19.570313 4 16 4 Z M 25.28125 19.625 C 23.824219 23.359375 20.289063 26 16 26 C 12.722656 26 9.84375 24.386719 8.03125 22 L 12 22 L 12 20 L 5 20 L 5 27 L 7 27 L 7 23.90625 C 9.1875 26.386719 12.394531 28 16 28 C 21.113281 28 25.382813 24.839844 27.125 20.375 Z" />
                </svg>
                <h2 className="mb-0">Subscription</h2>
                <span className="text-sm text-gray-600 font-thin my-2">
                  Full access to all our tools
                </span>
                <h3 className="flex item-center justify-center mx-auto  items-center text-5xl sans tracking-tight font-extrabold no-underline mx-3">
                  $49{" "}
                  <span className="ml-3 text-xl">
                    USD <br />
                    <span className="text-sm text-gray-500">/month</span>
                  </span>
                </h3>
              </div>
              <div className="h-2xl">
                <ul className="text-gray-600 mb-16">
                  <h3 className="uppercase text-lg text-left ">
                    What's included
                  </h3>
                  <li className="flex  items-center mx-auto">
                    <svg
                      aria-hidden="true"
                      width="12"
                      height="8"
                      fill="none"
                      className="text-teal-400 mr-3"
                    >
                      <path
                        d="M1.5 4.5l2.236 2.236a1 1 0 001.467-.056L10.5.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    Full database access
                  </li>
                  <li className="flex  items-center mx-auto">
                    <svg
                      aria-hidden="true"
                      width="12"
                      height="8"
                      fill="none"
                      className="text-teal-400 mr-3"
                    >
                      <path
                        d="M1.5 4.5l2.236 2.236a1 1 0 001.467-.056L10.5.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    Over 10 000 influencers (and growing)
                  </li>
                  <li className="flex  items-center mx-auto">
                    <svg
                      aria-hidden="true"
                      width="12"
                      height="8"
                      fill="none"
                      className="text-teal-400 mr-3"
                    >
                      <path
                        d="M1.5 4.5l2.236 2.236a1 1 0 001.467-.056L10.5.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    <span>
                      <span className="text-green font-semibold">20%</span>{" "}
                      discount on any personalized list orders.
                    </span>
                  </li>
                  <li className="flex  items-center mx-auto">
                    <svg
                      aria-hidden="true"
                      width="12"
                      height="8"
                      fill="none"
                      className="text-teal-400 mr-3"
                    >
                      <path
                        d="M1.5 4.5l2.236 2.236a1 1 0 001.467-.056L10.5.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    <span className="text-left">
                      Unlimited searches on ommifluence engagement calulator
                      tools.
                    </span>
                  </li>
                  <li className="flex  items-center">
                    <svg
                      aria-hidden="true"
                      width="12"
                      height="8"
                      fill="none"
                      className="text-teal-400 mr-3"
                    >
                      <path
                        d="M1.5 4.5l2.236 2.236a1 1 0 001.467-.056L10.5.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    <span className="text-left">
                      Expanded influencer data including charts & growth
                      forecast
                    </span>
                  </li>
                </ul>
              </div>
              <a
                title="Unavailable at the moment"
                className="block line-through bg-gray-500 text-white font-semibold font-sans lg:w-64 p-3 rounded-lg mx-auto mt-12 hover:shadow-xl cursor-not-allowed"
              >
                Next
              </a>
            </div>
          </div>
        </section>
        <p className="w-2/3 mx-auto text-xs font-thin text-gray-600 mb-0">
          DISCLOSURE theinfluencerlist.co is only a influencer aggregator and
          has no relationship or contact with any of the influencers on the
          list. None are sponsored or given preferential treatment, all are
          chosen based on merit and metrics alone. As such any contact or
          arrangements made between you and the influencer falls outside the
          teams liability. By buying any list you agree to not hold the team
          liable for any losses or issues you may incur.
        </p>
      </div>

      <GuaranteeBadge />
      <section className="w-full h-36 bg-grey pt-12 pb-24">
        {/* FAQ */}
        <h2 className="text-center text-3xl uppercase track-wider">
          Frequently Asked Question
        </h2>
        <div>
          <ul className="">
            {faq.map(el => (
              <li className="mx-10 my-8 divide-y-2 divide-gray-400">
                <h3 className="text-xl m-1">{el.q}</h3>
                <p className="italic m-0 pt-2 mb-4 pl-4">{el.a}</p>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </Layout>
  )
}

export default Pricing
